import Service from "../Service";

const resource = "articleTypeVisita/";

export default {
  save(prd, requestID) {
    return Service.post(resource + "save", prd, {
      params: { requestID: requestID },
    });
  },

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  //pdf
};
