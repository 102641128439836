<template>
  <s-crud @returnObject='refresh($event)'
    no-border
    title="Definiciones"
    :config="this.config"
    @save="saveDed($event)"
    add
    @clearForm="clearForm()"
    edit
    restore
    @rowSelected="rowSelected($event)" 
    remove
    :filter="filter"
    :height="150"   
  >
    <template scope="props">
      <v-container style="padding-top:0px;" v-if="props.item != null" class="pb-0">
        <v-row>
          <v-col cols="10" class="s-col-form">
            <s-text autofocus ref="DedDescription" v-model="props.item.DedDescription" label="Descripción Detalle" />
          </v-col>
          <v-col cols="2" class="s-col-form">
            <s-text :disabled="props.item.DedID == 0" v-model="props.item.DedValue" label="Valor" />
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text v-model="props.item.DedAbbreviation" label="Abreviatura" />
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text v-model="props.item.DedHelper" label="Utilitario" />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:SecStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
            {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template> 
  </s-crud>
</template>

<script>
import _sDefinitionDetail from "@/services/General/DefinitionDetailService";
export default {
  props: {
    defID: 0,
   
    defDescription: "",
  },
  data: () => ({
    dedValue:0,
      config: {
      title: "Definiciones Detalles",
      service: _sDefinitionDetail,
      model: {
        
        DedID: "ID",
        DefID: "int",
        DedDescription: "string",
        DedAbbreviation: "string",
        DedHelper: "string",
        DedValue: "int",
        SecStatus: "status",
      },
      headers: [
          {
            //
          text: "ID",
          value: "DedID",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Definicion",
          value: "DedDescription",
          width: "55%",
          sorteable: true,
        },
        {
          text: "Abreviatura",
          value: "DedAbbreviation",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Helper",
          value: "DedHelper",
          width: "15%",
          sorteable: false,
        },
        {
          text: "Valor",
          value: "DedValue",
          width: "5%",
          align: "center",
          sorteable: true,
        },
        {
          text: "Estado",
          value: "SecStatus",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
  }),

  computed: {
   
    filter() {
      return { DefID: 1202, SecStatus: 0 };
    },
  },
  methods: {
 rowSelected(items) {
      if (items.length > 0) this.dedValue = items[0].DedValue;
      this.$emit("rowSelected", items);
  },

    refresh(item){
      this.$emit('refresh',item);
    },
    clearForm() {
      this.$refs.DedDescription.focus();
    },
    saveDed(item) {
       item.DefID = "1202";
        item.UsrCreateID=0;
        if (item.DedDescription.length == 0) {
        this.$fun.alert("Registre descripción de valor", "warning");
        return;
      }
      item.save();       
    },
  },
};
</script>
