<template>
  <div>
    <v-row>
      <v-col lg="6">
        <cultive :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100"> >
        </cultive>        
      </v-col>
      <v-col lg="6">
          <articles :isAdmin="true"  :Dedvalue="dedID" :height="200"> >
        </articles> 
             
      </v-col>
  
      
     
    </v-row>
  </div>
</template>

<script>

import Cultive from "@/views/TechnicalAssistance/TechnicalVisit/Definition";
import Articles from '@/views/TechnicalAssistance/TechnicalVisit/Articles.vue';
export default {
  components: {
        Cultive
        , Articles
      //
  },

   data() {
    return {
      parentID: 0,
      dedID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.dedID=rows[0].DedValue;
    },
   
  },
};
</script>
