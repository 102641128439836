<template>
  
    <s-crud 
    title="Materiales"
    :config="configArticles"
    @save="save($event)"
    edit
    add
    remove   
    :filter="filter"
    search-input  
    ref="crud"

    >
       
        
  
   <template scope="props">
        <!--  <v-container class="pb-0"> -->
          <v-row justify="center">

              <s-select-articles v-model="props.item.ArtID" label="Material">
              </s-select-articles>


                <v-col cols="12" class="s-col-form" sm="4" md="8" lg="4">
              <s-select-definition v-model="props.item.AtvStatus" :def="1152" label="Estado"></s-select-definition>
            
            </v-col>                            
               </v-row>    
          </template>

           <template v-slot:AtvStatus="{ row }">
        <v-chip style="margin:0px" x-small :color="row.AtvStatus == 1 ? 'success' : 'error'">
            {{ row.AtvStatus == 1 ? "Activo" : "Inactivo" }}
        </v-chip>
      </template> 
     </s-crud>

</template>


<script>
import _sVarietyService from "@/services/Technicalassistance/TasArticleTypeVisitaService";


export default {
  name: "RequirementServiceVariety",
  components: {  },
  props: {
    Dedvalue:{ type: Number, default: 0 },
       parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogEdit: false,   
     };     
  },

  computed: {
   
    configArticles() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          AtvID: "ID",
          ArtDescription: "string",         
          DedDescription: "string", 
              AtvStatus: "status",
          //
          }, 
        service: _sVarietyService,
        headers:  this.$fun.getSecurity().IsLevelAdmin  
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "AtvID", sortable: false },
              { text: "Descripción", value: "ArtDescription", sortable: false },
              { text: "Tipo Visita", value: "DedDescription", sortable: false }, 
              { text: "Estado", value: "AtvStatus", sortable: false },           
            ]
          : [ 
                // CABECERA EN CASO NO SE LOGEE COMO ADMIN            
             { text: "ID", value: "AtvID", sortable: false },
              { text: "Descripción", value: "ArtDescription", sortable: false },
              { text: "Tipo Visita", value: "DedDescription", sortable: false }, 
              { text: "Estado", value: "AtvStatus", sortable: false },               
            ],
      };
    }, 
    
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
         },  
  },
  

  methods: {

 Initialize() {
   //DedID: this.DedID -- base de datos: campo actual
       this.filter = {DedValue: this.Dedvalue};
     
    },


    edit(item) {
      this.dialogEdit = true;
    
    },

    rowSelected(rows) {
      if (rows.length > 0) {
        this.$emit("rowSelected", rows);       
      }
    },

    save(item) {
     item.DedValue=this.Dedvalue
      
      if (this.$fun.getSecurity().IsLevelAdmin) {   
       if (item.DedID == 0) {
        this.$fun.alert("Seleccione el Tipo de Visita", "warning");
        return; 
        }
        item.SecStatus=1;
       item.save();
      }
       else {
        
       if (item.DedID == 0) {
         this.$fun.alert("Seleccione el Tipo de Visita", "warning");
        return; 
        }
        item.SecStatus=1;
       item.save();

      } 
      
      
    },    
  },
  watch: {
   Dedvalue(){   
        
      this.Initialize();    
      },
  },
   created() {
    this.filter = { Dedvalue: 0 };
  },
};
</script>
